import { useState, useEffect, useCallback } from "react";
import { TweetSingle } from "../../tweet-results-component/tweet-single-component/tweet-single-component";
import "./tweet-query-component.css";
import tweets from "../../../tweets.json";
import { TweetsPayload, TweetsResponse } from "../../../services/backend/models";

export interface TweetResult {
  setResults: (results: TweetSingle[]) => void;
  token: string;
}
// Easiest way to declare a Function Component; return type is inferred.
const TweetQuery = ({ setResults, token }: TweetResult) => {
  const [queryBeginDate, setQueryBeginDate] = useState(new Date(1672263423).toDateString());
  const [queryDuration, setQueryDuration] = useState(24 * 60 * 60 * 1000);
  const [usernameText, setUsernameText] = useState("alien");
  const [tweetText, setTweetText] = useState("dear Mahsa");
  const [hashtags, setHashtags] = useState("Revolution");
  const [mediaFormat, setMediaFormat] = useState("mp4");
  const [mediaType, setMediaType] = useState("VIDEO");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(20);

  const submit = useCallback(() => {
    if (token === "demo") {
      setResults(tweets.result);
      return;
    }
    const post_date_from = new Date(queryBeginDate).getTime();
    const post_date_to = post_date_from + queryDuration;
    console.log(post_date_from, post_date_to);
    const payload: TweetsPayload = {
      page,
      page_size: pageSize,
      order: ["-post_date"],
      search: {
        post_date_from,
        post_date_to,
        username: usernameText,
        tweet_text: tweetText,
        hashtags,
        media_format: mediaFormat,
        media_type: mediaType
      }
    };
    const requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
      body: JSON.stringify(payload)
    } as RequestInit;
    fetch('http://138.201.186.241/tweets', requestOptions)
      .then(response => response.json())
      .then((res: TweetsResponse) => {
        if (res.result) {
          setResults(res.result);
        }
      }).catch(err => {
        console.error(err);
      });
  }, [setResults, queryBeginDate, queryDuration, tweetText, hashtags, mediaFormat, mediaType, page, pageSize, usernameText, token]);

  useEffect(() => {
    submit();
  }, [submit]);

  return (
    <div className="query">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <div className="query__field">
          <p>
            Start date
          </p>
          <input
            placeholder=" "
            type="datetime-local"
            value={queryBeginDate}
            onChange={(event) => {
              setQueryBeginDate(event.target.value);
            }}
          />
        </div>
        <div className="query__field query_duration_input_div">
          <p>
            Time duration
          </p>
          <select name="query_duration" id="query_duration" onChange={(e) => setQueryDuration(Number(e.target.value))}>
            <option value={2 * 60 * 60 * 1000}>2 hours</option>
            <option value={8 * 60 * 60 * 1000}>8 hours</option>
            <option value={24 * 60 * 60 * 1000} defaultChecked={true}>24 hours</option>
            <option value={48 * 60 * 60 * 1000}>48 hours</option>
          </select>
        </div>
        <div className="query__field">
          <p>
            Username
          </p>
          <input
            placeholder=" "
            type="text"
            value={usernameText}
            onChange={(event) => {
              setUsernameText(event.target.value);
            }}
          />
        </div>
        <div className="query__field">
          <p>
            Tweet text
          </p>
          <input
            placeholder=" "
            type="text"
            value={tweetText}
            onChange={(event) => {
              setTweetText(event.target.value);
            }}
          />
        </div>
        <div className="query__field">
          <p>
            #hashtags
          </p>
          <input
            placeholder=" "
            type="text"
            value={hashtags}
            onChange={(event) => {
              setHashtags(event.target.value);
            }}
          />
        </div>
        <div className="query__field">
          <p>
            Media format
          </p>
          <input
            placeholder=" "
            type="text"
            value={mediaFormat}
            onChange={(event) => {
              setMediaFormat(event.target.value);
            }}
          />
        </div>
        <div className="query__field">
          <p>
            Media type
          </p>
          <input
            placeholder=" "
            type="text"
            value={mediaType}
            onChange={(event) => {
              setMediaType(event.target.value);
            }}
          />
        </div>
        <div className="query__field">
          <p>
            Page number
          </p>
          <input
            placeholder=" "
            type="text"
            value={page}
            onChange={(event) => {
              setPage(Number(event.target.value));
            }}
          />
        </div>
        <div className="query__field">
          <p>
            Page size
          </p>
          <input
            placeholder=" "
            type="text"
            value={pageSize}
            onChange={(event) => {
              setPageSize(Number(event.target.value));
            }}
          />
        </div>

        <div className="query__field">
          <button className={`query_button`}>{"Search"}</button>
        </div>
      </form>
    </div>
  );
};
export default TweetQuery;
