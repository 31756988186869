import React, { FormEventHandler, useState } from 'react';
import { LoginPayload, LoginResponse } from '../../services/backend';
import './login-component.scss';
import { FaUserAstronaut, FaLock } from "react-icons/fa";

function Login({ setToken }: { setToken: (token: string) => void }) {
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const onSubmitHandler: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const requestOptions = {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password } as LoginPayload)
    } as RequestInit;
    fetch('http://138.201.186.241/login', requestOptions)
      .then(response => response.json())
      .then((res: LoginResponse) => {
        if (res.success && res.token) {
          setToken(res.token);
          setError("");
        } else {
          setError("Login failed");
          setToken("demo");
        }
      }).catch(err => {
        setError("Something went wrong. Please try again later. Demo will open in 10 seconds.");
        console.error(err);
        setTimeout(() => {
          setToken("demo");
        }, 10000);
      });
  };
  return (
    <div className="align">
      <div className="grid">
        <form className="form login" onSubmit={onSubmitHandler}>
          <div className="form__field">
            <label htmlFor="login__username"><FaUserAstronaut /><span className="hidden">Username</span></label>
            <input id="login__username" type="text" name="username" className="form__input" placeholder="Username" required={true} onChange={(u) => setUsername(u.target.value)} />
          </div>
          <div className="form__field">
            <label htmlFor="login__password"><FaLock /><span className="hidden">Password</span></label>
            <input id="login__password" type="password" name="password" className="form__input" placeholder="Password" required={true} onChange={(u) => setPassword(u.target.value)} autoComplete={"current-password"} />
          </div>
          <div className="form__field">
            <input type="submit" value="Sign In" disabled={!username || !password} />
          </div>
        </form>
        {error &&
          <p className="text--center">{error}</p>}
      </div>
    </div>
  );
}

export default Login;