import { useEffect, useState } from "react";
import { TweetMedia } from "../tweet-single-component";
import "./tweet-single-medias-component.css";

// Declaring type of props - see "Typing Component Props" for more examples
type TweetProps = {
  medias: TweetMedia[];
  setMediaDownloadLinks: (mediaDownloadLinks: string[]) => void;
}; /* use `interface` if exporting so that consumers can extend */

const TweetSinglePhotoComponent = ({ media }: { media: TweetMedia }) => {
  return <img src={media.download_link} alt={media.media_filename} />;
};

const TweetSingleVideoComponent = ({ media }: { media: TweetMedia }) => {
  return <video src={media.download_link} controls={true} />;
};

// Easiest way to declare a Function Component; return type is inferred.
const TweetSingleMediasComponent = ({
  medias,
  setMediaDownloadLinks,
}: TweetProps) => {
  const [videos, setVideos] = useState<TweetMedia[]>([]);
  const [photos, setPhotos] = useState<TweetMedia[]>([]);

  useEffect(() => {
    const photosReceived = [] as TweetMedia[];
    const videosReceived = [] as TweetMedia[];
    const mediaDownloadLinks = [] as string[];
    medias.forEach((m) => {
      if (m.media_type === "VIDEO") {
        videosReceived.push(m);
      }
      if (m.media_type === "PHOTO") {
        photosReceived.push(m);
      }
      mediaDownloadLinks.push(m.download_link);
    });
    setPhotos(photosReceived);
    setVideos(videosReceived);
    setMediaDownloadLinks(mediaDownloadLinks);
  }, [medias, setPhotos, setVideos, setMediaDownloadLinks]);

  const mediasOut = [];
  for (const media of videos) {
    mediasOut.push(
      <TweetSingleVideoComponent media={media} key={media.id + "video"} />
    );
  }
  for (const media of photos) {
    mediasOut.push(
      <TweetSinglePhotoComponent media={media} key={media.id + "photo"} />
    );
  }

  return <div className={"tweet-media"}>{mediasOut}</div>;
};

export default TweetSingleMediasComponent;
