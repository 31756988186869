import { useEffect, useState } from "react";
import TweetLinkComponent from "../../tweet-single-link-component";

// Declaring type of props - see "Typing Component Props" for more examples
type TweetProps = {
  hashtags: string;
  className?: string;
}; /* use `interface` if exporting so that consumers can extend */

// Easiest way to declare a Function Component; return type is inferred.
const TweetSingleHashComponent = ({ hashtags, className }: TweetProps) => {
  const [hashes, setHashes] = useState(new Map<string, string>());

  useEffect(() => {
    const hashesMap = new Map<string, string>();
    hashtags.split(", ").forEach((h) => {
      hashesMap.set(
        `#${h}`,
        `https://twitter.com/search?q=%23${h}&src=trend_click&vertical=trends`
      );
    });
    setHashes(hashesMap);
  }, [setHashes, hashtags]);

  const hashComponents = [];
  for (const hash of Array.from(hashes.keys())) {
    hashComponents.push(
      <TweetLinkComponent
        link={hashes.get(hash)!}
        name={hash}
        key={hash}
        className={className}
      ></TweetLinkComponent>
    );
  }
  return <div className="hashtags">{hashComponents}</div>;
};

export default TweetSingleHashComponent;
