import { useState } from "react";
import "./App.css";
import Login from "./components/login-component";
import TweetComponent from "./components/tweet-component";

function App() {
  const [token, setToken] = useState<string>();
  return token ? (<TweetComponent token={token} />) : (<Login setToken={setToken} />);
}

export default App;
