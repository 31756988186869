import { useState } from "react";
import TweetResults from "../tweet-results-component";
import { TweetSingle } from "../tweet-results-component/tweet-single-component/tweet-single-component";
import "./tweet-component.scss";
import TweetQuery from "./tweet-query-component";

function TweetComponent({ token }: { token: string }) {
    const [results, setResults] = useState<TweetSingle[]>([]);

    return (
        <div className={"tweet"}>
            <TweetQuery setResults={setResults} token={token} />
            <TweetResults results={results} />
        </div>
    );
}

export default TweetComponent;
